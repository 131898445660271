import axios from 'axios'
const service = axios.create({
    baseURL: 'https://saas.weizhudata.com', // url = base url + request url
    timeout: 5000 // request timeout
})
// request interceptor
service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)
// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        return res
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)
export default service