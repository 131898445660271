import React, { Component } from 'react'
import style from './index.module.sass'
import Service from '@/components/service'

export default class index extends Component {
    constructor() {
        super()
        this.state = {
            power: [
                {
                    url: require('@/assets/newImages/pc/one1.png'),
                    title: '数字化营销',
                    text: '实现线索流程自动管理，无缝衔接客户管理模块，智能跟进和沉淀'
                },
                {
                    url: require('@/assets/newImages/pc/one2.png'),
                    title: '海量企业数据',
                    text: '覆盖全国3.2亿工商数据，20+项多种类、多行业客群画像专利模型'
                }, {
                    url: require('@/assets/newImages/pc/one3.png'),
                    title: '微助云呼系统',
                    text: '三种版本：独立版、机群版、API接口服务，满足企业不同阶段需求'
                }, {
                    url: require('@/assets/newImages/pc/one4.png'),
                    title: '系统智能化',
                    text: '集成云计算、大数据，针对不同客户资质，智能匹配贷款产品'
                },
            ],
            functions: [
                {
                    url: require('@/assets/newImages/pc/two1.png'),
                    title: '商机线索',
                    text: '拥有全国3.2亿+工商数据库，经过数据算法建模、清洗加工、解析关键人信息，接通率高，成交效果好。'
                },
                {
                    url: require('@/assets/newImages/pc/two2.png'),
                    title: '数字资产管理',
                    text: '通过线索池、跟进记录等功能，实现全周期地跟进记录与分类分级管理'
                }, {
                    url: require('@/assets/newImages/pc/two3.png'),
                    title: '微助云呼',
                    text: '独立版：适合微小企业；集群版：适合中大型企业；API接口服务：支持对接第三方外呼'
                }, {
                    url: require('@/assets/newImages/pc/two4.png'),
                    title: '短信营销',
                    text: '进一步提高目标客户商机触达机会，挖掘潜在需求，实现“高效率、低成本”，解决营销难题'
                },
                {
                    url: require('@/assets/newImages/pc/two5.png'),
                    title: '数据报表',
                    text: '拥有系统报表功能，包含客户转化率、客户数量排名等。可为企业提供营销可视化的决策支持'
                },
                {
                    url: require('@/assets/newImages/pc/two6.png'),
                    title: '独有专利模型',
                    text: '拥有20+项多种类、多行业客群画像专利模型，为你找到有效目标客户'
                },
            ],
            data: [
                {
                    title: '8,049,039',
                    text: '拓客线索 - 累计搜索量 (次)',
                    h5Text: '拓客线索',
                    h5Unit: '累计搜索量 (次)',
                },
                {
                    title: '1,193,803',
                    text: '线索跟进 - 累计数据 (条)',
                    h5Text: '线索跟进',
                    h5Unit: '累计数据 (条)',
                }, {
                    title: '213,405',
                    text: '单月使用 - 累计量 (次)',
                    h5Text: '单月使用',
                    h5Unit: '累计量 (次)',
                }, {
                    title: '101,138,200',
                    text: '系统外呼 - 累计量 (次)',
                    h5Text: '系统外呼',
                    h5Unit: '累计量 (次)',
                },
            ],
            an: {
                hint: '某某公司主要从事融资咨询服务、资产评估、破产清算服务、非融资担保等相关服务。在互联网和大数据被广泛运用的社会大环境下，以传统获客方式为主战场的某某公司，开始感受到了拓客压力。经过仔细复盘，发现拓客任务受阻的根本原因在于缺乏获客渠道。在负责人的观察对比下，最终选择了好微助SaaS。',
                before: [
                    '获客渠道不稳定，客户线索参差不齐，业务员一天下来很难开发2、3个客户；',
                    '以往公司整体一天下来最多完成400万左右的放贷额度；',
                    '一天打1000条客户名单，接通率20%左右，业务员没有信心做业绩，业绩目标难以达标。'
                ],
                after: [
                    '有稳定的客户来源，员工平均可以开发 3~5个客户，工作也变得有信心了；',
                    '现在稳定 800 多万～1000万，相比之前整整翻了 1.5 倍，依然在持续增长；',
                    '之前用了1000条进行验证，接通率在 65%，打出 14个意向客户。'
                ]
            },
            afterSale: [
                {
                    url: require('@/assets/newImages/pc/six1.png'),
                    title: '售前顾问产品演示',
                    text: '售前环节产品演示，让您全面认识产品价值，并结合企业实际情况，给出最佳方案建议'
                },
                {
                    url: require('@/assets/newImages/pc/six2.png'),
                    title: '1对1专属运营管家',
                    text: '使用中遇到的任何问题，都可以找专属客户经理，全程跟踪式服务，解决使用售后难题'
                }, {
                    url: require('@/assets/newImages/pc/six3.png'),
                    title: '技术团队快速响应',
                    text: '后台开通，实施交付，使用培训，售后支撑等全生命周期维护，更好地帮助企业落地使用'
                }, {
                    url: require('@/assets/newImages/pc/six4.png'),
                    title: '需求直达',
                    text: '产品顾问直接对接服务，需求反馈直达产研团队，快速高效更新迭代。'
                },
            ]
        }
    }
    render() {
        return (
            <div className={style.container}>
                <Service />
                {/* 能力 */}
                <div className={style.contentTai + ' box ' + style.contentOne}>
                    <div className={style.content}>
                        <img className={`${style.itemTitlePc} ${style.onePc}`} src={require('@/assets/newImages/pc/title1.png')} alt="title" />
                        <div className={style.items}>
                            {this.state.power.map(item => {
                                return <div className={style.item} key={item.title}>
                                    <img className={style.icon} alt="icon" src={item.url} />
                                    <div className={style.title}>{item.title}</div>
                                    <div className={style.text}>{item.text}</div>
                                </div>
                            })}
                        </div>
                        <img className={style.bottomImg} alt="bottom" src={require('@/assets/newImages/h5/itemBottom.png')} />
                    </div>
                </div>
                {/* 功能点 */}
                <div className={style.contentTai + ' box ' + style.contentTwo}>
                    <div className={style.content}>
                        <img className={`${style.itemTitlePc} ${style.twoPc}`} src={require('@/assets/newImages/pc/title2.png')} alt="title" />
                        <div className={`${style.items} ${style.items2}`}>
                            {this.state.functions.map((item, index) => {
                                return <div className={style.item} key={item.title}>
                                    <img className={style.icon} alt="icon" src={item.url} />
                                    <div>
                                        <div className={style.title}><span className={style.blueNum}>0{index + 1}. </span>{item.title}</div>
                                        <div className={style.text}>{item.text}</div>
                                    </div>
                                </div>
                            })}
                        </div>
                        <img className={style.bottomImg} alt="bottom" src={require('@/assets/newImages/h5/itemBottom.png')} />
                    </div>
                </div>
                {/* 数据 */}
                <div className={style.contentTai + ' box ' + style.contenthree}>
                    <div className={style.content}>
                        <img className={`${style.itemTitlePc} ${style.twoPc}`} src={require('@/assets/newImages/pc/title3.png')} alt="title" />
                        <div className={style.hint}>参考数据统计时间  2022/12/12 ~ 2023/01/12</div>
                        <div className={`${style.items} ${style.items3}`}>
                            {this.state.data.map(item => {
                                return <div className={style.item} key={item.title}>
                                    <div className={style.title}>{item.title}</div>
                                    <div className={`${style.text} ${style.textPc}`}>{item.text}</div>
                                    <div className={`${style.text} ${style.textH5}`}>{item.h5Text}</div>
                                    <div className={`${style.text} ${style.textH5}`}>{item.h5Unit}</div>
                                </div>
                            })}
                        </div>
                        <img className={style.bottomImg} alt="bottom" src={require('@/assets/newImages/h5/itemBottom.png')} />
                    </div>
                </div>
                {/* 案例 */}
                <div className={style.contentTai + ' box ' + style.contenFour}>
                    <div className={style.content}>
                        <img className={`${style.itemTitlePc} ${style.twoPc}`} src={require('@/assets/newImages/pc/title4.png')} alt="title" />
                        <div className={style.hint}>
                            {this.state.an.hint}
                        </div>
                        <div className={style.items4}>
                            <div className={style.item}>
                                <div className={style.title}>使用好微助SaaS前：</div>
                                <div className={style.texts}>
                                    {
                                        this.state.an.before.map((item, index) => {
                                            return <div className={style.row} key={item}>
                                                <div className={style.circle}>0{index + 1}</div>
                                                <div className={style.text}>{item}</div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className={`${style.item} ${style.blueItem}`}>
                                <div className={`${style.title} ${style.title2}`}>使用好微助SaaS后：</div>
                                <div className={style.texts}>
                                    {
                                        this.state.an.after.map((item, index) => {
                                            return <div className={style.row} key={item}>
                                                <div className={style.circle}>0{index + 1}</div>
                                                <div className={style.text}>{item}</div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 售后 */}
                <div className={style.contentTai + ' box ' + style.contentfive}>
                    <img className={`${style.itemTitlePc} ${style.oneH5}`} src={require('@/assets/newImages/h5/title5_h5.png')} alt="title" />
                    <div className={style.content}>
                        <img className={`${style.itemTitlePc} ${style.onePc}`} src={require('@/assets/newImages/pc/title5.png')} alt="title" />
                        <div className={style.items}>
                            {this.state.afterSale.map(item => {
                                return <div className={style.item} key={item.title}>
                                    <img className={style.icon} alt="icon" src={item.url} />
                                    <div className={style.circle}></div>
                                    <div className={style.line} />
                                    <div>
                                        <div className={style.title}>{item.title}</div>
                                        <div className={style.text}>{item.text}</div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                {/* footer  */}
                <div className={style.footer + ' box'}>
                    <div className={`${style.text}  ${style.four}`}>
                        <a href='http://www.weizhudata.com/' target='_blank'>由知助数智团队提供数据支持</a>
                        <a className={style.dou}>，</a>
                        <a href='#'>由好微助SaaS团队提供技术支持</a></div>
                    <div className={style.row}>
                        <span className={`${style.text} ${style.one}`}>公司名称：知助数智（重庆）科技有限公司</span>
                        <span className={style.text}>公司地址：重庆市江北区国华金融中心A塔18楼</span>
                    </div>
                    <div className={`${style.text} ${style.three}`}> <a  href='https://beian.miit.gov.cn/' target='_blank'>渝ICP备2023009542号-2</a>Copyright &copy;{new Date().getFullYear()} 知助数智 All Rights Reserved</div>
                </div>
            </div>
        )
    }
}
