import React, { Component } from 'react'
import style from './index.module.sass'
export default class index extends Component {
    render() {
        return (
            <div className={style.container}>
                <div className={style.content + ' box'}>
                    <img className={style.logo} src={require('@/assets/images/logo.png')} alt="logo" />
                    <div className={style.left}>
                        <div className={style.title1}>好微助SaaS <span className={style.pcTitle}> - 助力贷款中介获客增长</span></div>
                        <div className={style.title2}>助力贷款中介获客增长</div>
                        <div className={style.text}>好微助SaaS，是一款专注于金融贷款服务场景的客户关系管理系统，满足行业上所有常见的线下场景及多种贷款业务，为金融机构提供贷款业务管理、贷款线索、客户管理、销售管理、大数据风控、数据统计，等企业数字化解决方案。</div>
                    </div>
                </div>
            </div>
        )
    }
}
