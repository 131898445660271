import React, { Component } from 'react'
import style from './index.module.sass'
import { Modal } from 'antd';
export default class service extends Component {
    constructor() {
        super()
        this.state = {
            isShow: true,
            isModalOpen: false,
        }
        this.tels = null
    }
    changeShow = () => {
        this.setState({
            isShow: !this.state.isShow
        })
    }
    changeShowH5 = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        })
    }
    stopBu = (e) => {
        e.stopPropagation();
    }
    callPhone = () => {
        this.tels.href = `tel://18523622387`
        this.tels.click()
    }
    render() {
        return (
            <div className={style.container}>
                <div className={style.pc}>
                    <div className={style.show} onClick={this.changeShow}>
                        <img className={style.img} alt="img" src={require('@/assets/newImages/pc/service.png')} />
                        <div className={style.text}>联系客服</div>
                    </div>
                    <div className={`${style.hide} ${this.state.isShow ? '' : style.dis}`}>
                        <div className={style.title}>扫码联系 <span className={style.blue}>产品顾问</span></div>
                        <img className={style.service} alt="service" src={require('@/assets/newImages/pc/service_ma.png')} />
                        <div className={style.text}>
                            咨询专线：18183065450
                        </div>
                        <img className={style.triangle} alt={style.triangle} src={require('@/assets/newImages/pc/triangle.png')} />
                    </div>
                </div>
                <div className={style.h5}>
                    <div className={style.item} onClick={this.changeShowH5}>
                        <img className={style.img} alt="img" src={require('@/assets/newImages/h5/service.png')} />
                        <div className={style.text}>联系客服</div>
                    </div>
                    <div className={style.item} onClick={this.callPhone}>
                        <img className={style.img} alt="img" src={require('@/assets/newImages/h5/telephone.png')} />
                        <div className={style.text}>电话联系</div>
                    </div>
                </div>
                <a href="" className={style.a} ref={ref => this.tels = ref}></a>
                {/* <Modal title="" open={this.state.isModalOpen} footer={null} maskClosable={true} onCancel={this.changeShowH5} closable={false} destroyOnClose={true}>
                    <img className={style.serviceH5} alt="service" src={require('@/assets/newImages/pc/service_ma.png')} />
                </Modal> */}
                <div className={`${style.model}  ${this.state.isModalOpen ? '' : style.hideModel}`} onClick={this.changeShowH5}>
                    <img className={style.serviceH5} alt="service" src={require('@/assets/newImages/pc/service_ma.png')} onClick={this.stopBu} />
                </div>
            </div>
        )
    }
}
