import React, { Component } from 'react'
import Header from '@/views/header'
// import Content from '@/views/content'
import Content from '@/views/newContent'

import './index.css'
import { getSign } from '@/api/index'
export default class layout extends Component {
    constructor() {
        super()
        this.wxShare()
        this.state = {
            items: [
                {
                    title: '线索丰富化',
                    text: '好微助SaaS拓客线索模块覆盖全网企业信息、精准联系方式、经营信息、资产信息、年报信息、关系链信息等（来源为网络公开渠道），海量潜在优质客户，随用随查。提供多维度条件筛选，快速锁定目标客户，加速成交、提升业绩。',
                    tags: ['拓客线索', '获取客户', '导入客户池'],
                    img: {
                        url: require('@/assets/images/pc/one.png'),
                        bgImg: require('@/assets/images/h5/list1.png'),
                        list: [
                            {
                                url: require('@/assets/images/pc/one1.png'),
                                top: '-3.5rem',
                                left: '18rem',
                            },
                            {
                                url: require('@/assets/images/pc/one2.png'),
                                left: '-4rem',
                                bottom: '-4rem'
                            },
                            {
                                url: require('@/assets/images/pc/one3.png'),
                                bottom: '-4rem',
                                right: '-4rem',
                            },
                        ]
                    }
                },
                {
                    title: '客户标准化',
                    text: '客户标准化是好微助SaaS的核心模块，帮助机构有效管理客户，360°客户全景资料，实现客户信息标准化。有效帮助销售人员对客户进行管理、防止了客户资源的浪费，实现客户资源价值最大化。',
                    tags: ['客户分配', '客户交接', '权限分层', '公海回收'],
                    img: {
                        url: require('@/assets/images/pc/two.png'),
                        bgImg: require('@/assets/images/h5/list2.png'),
                        list: [
                            {
                                url: require('@/assets/images/pc/two1.png'),
                                top: '4rem',
                                right: '-4rem',
                            },
                            {
                                url: require('@/assets/images/pc/two2.png'),
                                right: '-4rem',
                                bottom: '-2rem'
                            },
                            {
                                url: require('@/assets/images/pc/two3.png'),
                                bottom: '-4rem',
                                left: '-4rem',
                            },
                        ]
                    }
                }, {
                    title: '业务流程化',
                    text: '每个机构都拥有自身的业务流程管理，好微助SaaS业务流程功能针对不同贷款机构在业务流程痛点，从贷中、贷前、贷后三个维度进行了业务流程的系统管理，支持系统自定义，有效提高了企业员工的协同效率。',
                    tags: ['员工管理', '角色管理', '部门管理', '岗位管理'],
                    img: {
                        url: require('@/assets/images/pc/three.png'),
                        bgImg: require('@/assets/images/h5/list3.png'),
                        list: [
                            {
                                url: require('@/assets/images/pc/three1.png'),
                                bottom: '-3rem',
                                left: '-4rem',
                            },
                            {
                                url: require('@/assets/images/pc/three2.png'),
                                right: '-4rem',
                                top: '-3.5rem'
                            },
                        ]
                    }
                }, {
                    title: '产品管理化',
                    text: '产品是机构销售业务的核心资料，也是企业的核心竞争力。好微助SaaS产品管理功能将所有的资方产品进行统一管理，解决贷款机构资料过多、产品杂乱、等众多问题，大大地加强了产品的匹配成交率。',
                    tags: ['银行管理', '产品管理', '渠道管理'],
                    img: {
                        url: require('@/assets/images/pc/four.png'),
                        bgImg: require('@/assets/images/h5/list4.png'),
                        list: [
                            {
                                url: require('@/assets/images/pc/four2.png'),
                                bottom: '-3rem',
                                left: '-4rem',
                            },
                            {
                                url: require('@/assets/images/pc/four1.png'),
                                right: '-4rem',
                                top: '-3.5rem'
                            },
                        ]
                    }
                }, {
                    title: '业务可视化',
                    text: '数据统计模块展示了各项业务数据的图表化分析结果，好微助SaaS提供了丰富的报表维度和可视化仪表盘。机构经营情况了如指掌，业务人员业绩情况清晰可见，赋能机构决策辅助。',
                    tags: ['个人统计', '渠道统计'],
                    img: {
                        url: require('@/assets/images/pc/five.png'),
                        bgImg: require('@/assets/images/h5/list5.png'),
                        list: [
                            {
                                url: require('@/assets/images/pc/five2.png'),
                                bottom: '-3rem',
                                left: '-4rem',
                            },
                            {
                                url: require('@/assets/images/pc/five1.png'),
                                right: '-3.5rem',
                                top: '-3.5rem'
                            },
                        ]
                    }
                },
            ]
        }
    }
    wxShare = () => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
        document.head.appendChild(script);
        script.onload = async () => {
            let res = await getSign();
            window.wx.config({
                debug: false,
                appId: "wx61f4976e353ff072", // 此处APPID为公众号的APPID
                timestamp: res.data.timestamp,
                nonceStr: res.data.nonceStr,
                signature: res.data.signature,
                jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], //此处必须填写一个，不为null就行
            });
            window.wx.ready(function () {
                let linkUrl = window.location.href;
                let shareInfo = {
                    title: `好微助SaaS`, //分享的标题
                    desc: "全国3.2亿+全量工商数据;多行业客群画像专利模型，为您找到有效目标客户！", //分享的描述
                    link: linkUrl, //分享的链接
                    imgUrl: "https://oss.weizhudata.com/crm/website/shareLogo.png", //分享的图片链接
                    success(response) {
                        console.log("user shared");
                    },
                };
                window.wx.updateAppMessageShareData(shareInfo);
                window.wx.updateTimelineShareData(shareInfo);
            });
        }
    }
    render() {
        return (
            <div className='layout'>
                <Header />
                {/* <Content items={this.state.items} /> */}
                <Content />
            </div>
        )
    }
}
